import Rete from "rete";
import * as Socket from "../sockets";

export class Launcher extends Rete.Component {
  constructor() {
    super("Launcher");
  }

  builder(node) {
    const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

    return node.addOutput(out);
  }

  worker(node, inputs, outputs) {
    outputs['out'] = ""
  }
}
