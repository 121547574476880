export default {
    setItems(state, items) {
        state.items = items;
    },

    //Приводим приходящие данные к необходимому формату для Rete.JS
    formatItems(state, items) {

        var j = 0;
        items.map(i => {
            let type = i.type[0].toUpperCase() + i.type.slice(1) //launcher to "Launcher"

            //позиция по-умолчанию если ранее не создавалось на сервере
            let posX = -j * 500; //смещаем элемент влево по X
            let posY = j * 100; // смещаем элемент внищ по y


            //Если позиция задана на сервере
            if (i.position) {

                //Для варианта когда Position на сервере задан в position: []
                let isArray = Object.prototype.toString.call(i.position) === '[object Array]'
                if (isArray) {
                    posX = i.position[0]
                    posY = i.position[1]
                }

                //Для варианта когда Position на сервере задан в position: ['block':'', 'index':'']
                var isNumbers = typeof (i.position.block) === "number" && typeof (i.position.index) === "number";
                var isSetted = i.position.block && i.position.index;
                if (isSetted && isNumbers) {
                    posX = i.position.block
                    posY = i.position.index
                }
            }

            let output = {};

            if (i.target) {
                //добавляем соединениее узлов
                output['out'] = { "connections": [{ "node": i.target, "input": "in" }] };
            }

            if (type == "Condition") {
                posY -= 400
                let count = 0
                i.parameters.map(limitation => {
                    if (limitation.target) {
                        let name = String(limitation.operation) + String(limitation.target) + String(i.id) + count; // TODO move to common functions, also uses in Condition component name 
                        output[name] = { "connections": [{ "node": limitation.target, "input": "in" }] };
                    }
                    count++;
                })
                

            }

            // добавляем соединения для кнопок (множественные для сообщений)
            let buttons = i.parameters?.buttons ?? false
            if (buttons) {
                let i = 0
                buttons.map(param => {
                    if (param.target) {
                        let name = String(param.type) + String(param.target) + i
                        output[name] = { "connections": [{ "node": param.target, "input": "in" }] } //подключаем кнопку к ожидаемому узлу
                    }
                    param.id = i
                    i++;
                })
            }

            // добавляем соединения для кнопки контакта (одна кнопка)
            let button = i.parameters?.button || false
            if (button) {
                if (button.target) {
                    let name = String(button.type) + String(button.target)
                    output[name] = { "connections": [{ "node": button.target, "input": "in" }] } //подключаем кнопку к ожидаемому узлу
                }
            }

            let formattedItem = {
                "id": i.id,
                "data": i.parameters,
                "group": null,
                "inputs": {},
                "outputs": output,
                "position": [posX, posY],
                "name": type
            };

            items[j] = formattedItem;
            j++;
        })
    },

    //Обновление данных при редактировании компонента
    update(state, item) {
        state.items = state.items.map(i => {
            if (i.id === item.id) {
                return { ...item, i }
            }
            return i
        })
    },

    //Создание нового компонента пользователем
    create(state, item){
        state.items.push(item)
    },

    // Сохранить Rete Node с которым происходит работа
    setChosenItem(state, chosenItem) {
        state.chosenItem = chosenItem
    },

    // Проверка состояния открытого модального окна
    setIsAddButtonModal(state, isAddButtonModal) {
        state.isAddButtonModal = isAddButtonModal
    },

    setIsChangeButtonModal(state, isChangeButtonModal){
        state.isChangeButtonModal = isChangeButtonModal
    },

    setIsChangeContactButtonModal(state, isChangeContactButtonModal){
        state.isChangeContactButtonModal = isChangeContactButtonModal
    },

    setIsChangeConditionButtonsModal(state, isChangeConditionButtonsModal){
        state.isChangeConditionButtonsModal = isChangeConditionButtonsModal
    },
    
    setIsChangeLimitationsModalOpen(state, isChangeLimitationsModalOpen){
        state.isChangeLimitationsModalOpen = isChangeLimitationsModalOpen
    },

    updateNodeIdByServerResponse(state, {oldNode, nodeByServerResponse, reteNode} ){
        // Найти Node в state
        let stateNodeINdex = state.items.findIndex(node => node.id === oldNode.id)
        state.items[stateNodeINdex].id = nodeByServerResponse.id

        // Обновить Node в Rete 
        reteNode.id = nodeByServerResponse.id
        reteNode.update()

        console.log(state.items[stateNodeINdex])

        //Обновить данные Rete 
        state.nodeIdByServerResponse = nodeId
    }
}
