 <template>
  <div id="modal">
    <Modal
      :visible="isAddButtonModal"
      @cancel="onClose"
      @ok="onOk"
      title="Редактирование кнопок"
    >
      <div v-if="this.chosenItem">
        <div
          v-for="(output, index) in this.chosenItem.outputs"
          :key="index"
          class="field"
        >
          <span v-if="output[0] != 'out'">
            <Button :block="true" @click="chooseButton(output, index)">
              {{ output[1].name }}
            </Button>
            <Button :block="true" @click="chooseButton(output, index);deleteButton(output, index);">
              <Icon type="delete" />
            </Button>
          </span>
        </div>
      </div>

      <div class="field">
        <Button :block="true" @click="onOk();cleanDataInputs()" type="primary">
          Добавить новую кнопку
        </Button>
      </div>

      <br />
      <div class="buttonChange">
        <h3>Параметры кнопки:</h3>
        <div class="field">
          <Input
            :value="buttonName"
            @input="handleInput"
            placeholder="Название кнопки (Обязательное)*"
            name="inputButtonName"
          />
        </div>
        <div class="field">
          <Input
            :value="buttonUrl"
            @input="handleInput"
            placeholder="URL ссылка (https://site.com)"
            name="inputButtonUrl"
          />
        </div>

        <div class="field">
          <RadioGroup v-model="buttonType">
            <Radio :defaultChecked="true" value="none">None</Radio>
            <Radio value="link">Link</Radio>
          </RadioGroup>
        </div>
      </div>
      <br />

      <template #footer>
        <span v-if="isAddNewButton">
          <Button key="cancel" @click="onClose">Отменить</Button>
          <Button key="ok" type="primary" @click="onOk">Создать</Button>
        </span>
        <span v-else>
          <Button key="cancel" @click="onClose">Отменить</Button>
          <Button key="ok" type="primary" @click="onOk">Сохранить</Button>
        </span>
      </template>
    </Modal>
  </div>
</template>

<script>
import * as ReteEngine from "rete";
import * as Socket from "@/node-editor/sockets.js";
import Vue from "vue";

import { Modal, Radio, Input, Button, Icon, Row, Col } from "ant-design-vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

Vue.use(Modal);

export default {
  name: "MessageButtonsModal",
  components: {
    Modal,
    Input,
    RadioGroup: Radio.Group,
    Radio,
    Button,
    Icon,
    Row,
    Col,
  },
  data() {
    return {
      isAddNewButton: true,
      buttonType: "none",
      buttonName: "",
      buttonUrl: "",
      buttonId: 0 ,
      buttonTarget: null,
      outputId: null,
    };
  },
  computed: {
    ...mapGetters({
      isAddButtonModal: "elements/isAddButtonModal",
      isChangeButtonModal: "elements/isChangeButtonModal",
      chosenItem: "elements/chosenItem",
    }),
  },
  methods: {
    ...mapActions({
      setIsAddButtonModal: "elements/setIsAddButtonModal",
      setIsChangeButtonModal: "elements/setIsChangeButtonModal",
      updateItem: "elements/updateItem",
    }),

    handleInput: _.debounce(function (e) {
      if (e.target.name === "inputButtonName") {
        this.buttonName = e.target.value;
      }
      if (e.target.name === "inputButtonUrl") {
        this.buttonUrl = e.target.value;
      }
    }, 50),

    onClose() {
      this.setIsAddButtonModal(false);
    },

    _getNewElement(){
      return {
        ...this.chosenItem,
        data: {
          ...this.chosenItem.data,
          buttons: [...this.chosenItem.data?.buttons ?? []],
        },
      };
    },

    onOk() {
      if (this.buttonName === "") {
        //Проверка на заполнение
        // this.setIsAddButtonModal(false);
        return;
      }

      //Обновление Data данные для API
      let newEl = this._getNewElement()

    
      if (this.isAddNewButton) {
        //Добавление Output в Rete
        let buttonUnicalId = String(Date.now());
        let buttonOutput = new ReteEngine.Output(
          buttonUnicalId,
          this.buttonName,
          Socket.value,
          false
        );
        this.chosenItem.addOutput(buttonOutput);
        this.chosenItem.update();


        //Добавляем новую кнопку в data
        newEl.data.buttons.push({
          type: this.buttonType,
          target: this.chosenItem.outputs.get(buttonUnicalId).connections[0]?.input.node.id ?? null,
          parameters: {
            url: this.buttonUrl,
            name: this.buttonName,
          },
        });

      } else {

        // пользователь обновляет параметры существующей кнопки
        newEl.data.buttons[this.buttonId] = {
          type: this.buttonType,
          target: this.buttonTarget,
          parameters: {
            url: this.buttonUrl,
            name: this.buttonName,
          },
        };

        //обновляем ReteJS
        this.chosenItem.outputs.get(this.outputId).name = this.buttonName;
      }

      //API запрос
      this.updateItem({ data: newEl });

      //Сохраняем изменения в node
      this.chosenItem.data = newEl.data

      this.cleanDataInputs();
    },

    deleteButton(output, index, event) {
      //Удаление Output в Rete
        this.chosenItem.outputs.delete(this.outputId)
        this.chosenItem.update();
      //Удаление кнопки из Data в API
        let newEl = this._getNewElement()
        newEl.data.buttons.splice(index, 1);

      //Запрос
      this.chosenItem.data = newEl.data;
      this.updateItem({ data: newEl });
      this.cleanDataInputs();

    },

    //выбираем назначенную кнопку и сохраняем ее параметры
    chooseButton(output, index, event) {
      this.isAddNewButton = false;
      let params = output[1]?.node.data.buttons[index];
      this.buttonUrl = params?.parameters.url ?? "";
      this.buttonType = params?.type ?? "";
      this.buttonName = params?.parameters.name ?? "";
      this.buttonId = params?.id ?? 0;
      this.buttonTarget = params?.target ?? null;
      this.outputId = output[0];
    },
    //пользователь хочет создать новую кнопку - обнулим параметры ввода
    cleanDataInputs() {
      this.isAddNewButton = true;
      this.buttonUrl = "";
      this.buttonType = "none";
      this.buttonName = "";
      this.buttonId = 0;
      this.buttonTarget = null;
      this.outputId = null;
    },

    onCloseChangeModal() {
      this.setIsChangeButtonModal(false);
    },
  },
};
</script>

<style lang="sass">
@import "./index.sass"
</style>