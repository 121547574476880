<template>
  <textarea
    :value="value"
    @input="change"
    :class="s.textArea"
    :style="{ 'background-color': color }"
  />
</template>

<script>
import s from "./index.module.sass";

export default {
  props: ["value", "change", "color"],
  data() {
    return {
      s,
    };
  },
};
</script>
