import Rete from "rete";

import Component from "./index.vue";

export class ButtonControl extends Rete.Control {
    constructor(content, funcName, key, emitter) {
        super(key);

        this.component = Component;
        this.props = {
            content,
            funcName,
            emitter
        };
    }

    setValue(val) {
        this.vueContext.value = val;
    }
}
