import { instance } from "./index"

export const elementAPI = {
    async fetchItems() {
        const { data } = await instance.get(``)
        return data.response.elements
    },

    //WARNING!! При удалении элемента необходимо удалить все target указывающие на этот элемент
    //TODO - требования к серверу
    async deleteItem(id) {
        if (typeof (id) != 'undefined') {
            const res = await instance.delete(`/elements/${id}`)
            console.log('Server delete response:', res.data.response)
            return res.data
        }
    },

    /**
     * 
     * @param {data} см src/store/modules/elements/actions.js updateItem 
     * @returns 
     */
    async update(data) {
        let id = data?.id

        if (typeof (id) != 'undefined') {
            try {
                const apiRes = await instance.put(`/elements/${id}`, { ...data })
                return apiRes
            }
            catch (error){
                console.log("Error:", error)
            }

        }
    },

    /**
     * 
     * @param {data} см src/store/modules/elements/actions.js createItem 
     * @returns 
     */
    async create(data) {
        let id = data?.id

        console.log("API res: ", data)
        if (typeof (id) != 'undefined') {
            const res = await instance.post(`/elements`, { ...data })
            return res.data.response
        }
    },

}