<template>
  <Upload
    name="image"
    id="image"
    :action="uploadUrl"
    :multiple="false"
    :default-file-list="fileList"
    :headers="headers"
    @change="change"
    style="color: white"
    list-type="picture"
  >
    <Icon type="upload" />
    Загрузить новое фото
  </Upload>
</template>

<script>
import UploadIcon from "@/assets/icons/upload.svg";
import { Upload, Icon } from "ant-design-vue";
import { baseURL, TOKEN, storageURL } from "@/api";
import { mapActions } from "vuex";

import s from "./index.module.sass";

export default {
  components: {
    Upload,
    Icon,
  },
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    change: {
      type: Function,
    },
    baseURL: "",
  },
  computed: {
    uploadUrl: function () {
      return `${baseURL}/files`;
    },

    fileList: function () {
      let img = [
        {
          uid: "-1",
          name: `${this.value}`,
          status: "done",
          url: `${storageURL}${this.value}`,
          thumbUrl: `${storageURL}${this.value}`,
        },
      ];

      if (this.value === "") {
        img = [];
      }

      return img;
    },
  },
  methods: {
    ...mapActions({
      setImage: "elements/setImage",
      setChosenItem: "elements/setChosenItem",
    }),

    handleChange(info) {
    },
  },
  data() {
    return {
      s,
      UploadIcon,
      headers: {
        authorization: `Bearer ${TOKEN}`,
      },
    };
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-flex;
  width: 100%;
}
</style>
