<template>
  <div id="app">
    <MessageButtonsModal />
    <ContactButtonModal />
    <ConditionButtonsModal />
    <Rete />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Rete from "./components/Rete";

import MessageButtonsModal from "./components/Common/Modal/MessageButtonsModal"
import ContactButtonModal from "./components/Common/Modal/ContactButtonModal"
import ConditionButtonsModal from "./components/Common/Modal/ConditionButtonsModal"

import Vue from 'vue';

export default {
  name: "App",
  components: { Rete, MessageButtonsModal, ContactButtonModal, ConditionButtonsModal},
  computed: {
    ...mapGetters({
      elements: "elements/items",
    }),
  }
};
</script>
 
 <style lang="sass">
@import "./assets/styles/index.sass"
</style>