<template>
  <button
    @click="click"
    :type="type"
    :class="[s.button, { [s.fullWidth]: fullWidth }]"
  >
    <slot />
  </button>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: ["type", "click", "fullWidth"],
  data() {
    return {
      s,
    };
  },
};
</script>
