<template>
  <div>
    <input
      :value="value"
      @input="onChangeValue"
      type="checkbox"
      :checked="newChecked"
    />

    <label style="color: white; text-transform: capitalize;"> {{ name }} </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  props: ["name", "value", "checked", "emitter"],
  data() {
    return {
      newChecked: this.checked,
    };
  },
  methods: {
    ...mapActions({
      updateItem: "elements/updateItem",
    }),

    onChangeValue: _.debounce(function (e) {
      this.newChecked = e.target.checked;
      this.updateItem({ data: this.emitter.selected.list[0] });
    }, 500),
  },
};
</script>
