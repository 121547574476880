<template>
  <select :name="name" :class="s.select" @change="change">
    <option v-for="option in options" :key="option" :selected=" selected === option ">
      {{ option }}
    </option>
  </select>
</template>

<script>
import s from "./index.module.sass";

export default {
  props: ["name", "options", "change", "selected"],
  data() {
    return {
      s,
    };
  },
};
</script>
