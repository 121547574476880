<template>
  <Input
    v-if="type === 'input'"
    :name="name"
    :value="newValue"
    :change="onChangeValue"
    :placeholder="placeholder"
    :color="color"
  />
  <TextArea
    v-else-if="type === 'textArea'"
    :name="name"
    :value="newValue"
    :change="onChangeValue"
    :placeholder="placeholder"
    :color="color"
  />
</template>

<script>
import Input from "@/components/Forms/Input";
import TextArea from "@/components/Forms/TextArea";
import { mapActions } from "vuex";
import _ from "lodash";

/**
 * red: #e57373
 * blue: #64b5f6
 * green: #81c784
 * yellow: #fff176
 */

export default {
  components: {
    Input,
    TextArea,
  },
  props: ["type", "name", "emitter", "value", "placeholder"],
  data() {
    return {
      color: "#ffffff",
      newValue: this.value,
    };
  },

  mounted() {
    this.setColor(this.name);
  },

  methods: {
    ...mapActions({
      updateItem: "elements/updateItem",
    }),

    setColor(name) {
      switch (name) {
        case "textRed":
          this.color = "#e57373";
          break;
        case "textBlue":
          this.color = "#64b5f6";
          break;
        case "textGreen":
          this.color = "#81c784";
          break;
        case "textYellow":
          this.color = "#fff176";
          break;
        default:
          this.color = "#ffffff";
      }
    },

    onChangeValue: _.debounce(function (e) {
      this.newValue = e.target.value;
      this.updateItem({ data: this.emitter.selected.list[0] });
    }, 1000),
  },
};
</script>
