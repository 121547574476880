import Rete from "rete";

import * as Socket from "@/node-editor/sockets.js";
import { CheckboxControl, SelectControl } from "@/node-editor/controls/index.js";
// import { InputControl } from "./components/Input/index.js";
import {InputControl } from "@/node-editor/controls/index.js";



export class Timer extends Rete.Component {
    constructor() {
        super("Timer");
    }

    builder(node) {
        const data = node.data;
        const delay = data.delay;
        const range = data.range;
        const daysData = data.days

        const delayTypes = ['seconds', 'minutes', 'hours']

        //стандартные ввод и вывод для соединений для всех элементов
        const input = new Rete.Input("in", "", Socket.value, true);
        const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

        //Данные компонента
        let delayValue = delay?.value ?? 0
        let delayType = delay?.type ?? delayTypes[0]
        let startAt = String(range?.startAt ?? "00:00")
        let endAt = String(range?.endAt ?? "23:59")

        const timeVal = new InputControl("input", "time", this.editor, delayValue, "Input value")
        const timeTypeVal = new SelectControl("timeType", delayTypes, delayType, this.editor)
        const durationFrom = new InputControl("input", "durationFrom", this.editor, startAt, startAt)
        const durationTo = new InputControl("input", "durationTo", this.editor, endAt, endAt)

        //отрисовываем чекбоксы
        let days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
        days.forEach(d => {
            let isChecked = daysData?.includes(d) ?? false
            let control = new CheckboxControl(d, d, isChecked, this.editor)
            node.addControl(control)
        });

        return node
            .addInput(input)
            .addOutput(out)

            .addControl(timeVal)
            .addControl(timeTypeVal)
            .addControl(durationFrom)
            .addControl(durationTo)
    }

    worker(node, inputs, outputs) {

    }
}
