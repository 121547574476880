import Rete from "rete";
import * as Socket from "@/node-editor/sockets.js";
import { ButtonControl, InputControl } from "@/node-editor/controls/index.js";

export class Message extends Rete.Component {
    constructor() {
        super("Message");
    }

    builder(node) {
        const data = node.data

        //стандартные ввод и вывод для соединений для всех элементов
        const input = new Rete.Input("in", "", Socket.value, true);
        const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

        const textAll = new InputControl("textArea", "textAll", this.editor, data?.text?.all ?? 'Введите сообщение');
        const textRed = new InputControl("textArea", "textRed", this.editor, data?.text?.red ?? '');
        const textBlue = new InputControl("textArea", "textBlue", this.editor, data?.text?.blue ?? '');
        const textGreen = new InputControl("textArea", "textGreen", this.editor, data?.text?.green ?? '');
        const textYellow = new InputControl("textArea", "textYellow", this.editor, data?.text?.yellow ?? '');
        const button = new ButtonControl("Управлять кнопками", "setIsAddButtonModal", node.id, this.editor);


        //Отрисовка кнопок
        if (data.buttons) {
            let i = 0
            data.buttons.map(element => {
                let name = String(element.type) + String(element.target) + i // TODO: name generator depencies in mutation.js, should move to common instruments functions uses in mutation.js
                let buttonOutput = new Rete.Output(name, element.parameters.name, Socket.value, false);
                node.addOutput(buttonOutput);
                i++;
            });
        }

        node
            .addInput(input)
            .addOutput(out)

            .addControl(textAll)
            .addControl(textRed)
            .addControl(textBlue)
            .addControl(textGreen)
            .addControl(textYellow)
            .addControl(button);

        return node;
    }

    worker(node, inputs, outputs) {
    }
}
