import Rete from "rete";

import Component from "./index.vue";

export class CheckboxControl extends Rete.Control {
    constructor(name, value, checked, emitter) {
        super(name);

        this.component = Component;
        this.props = {
            name, value, checked, emitter
        };
    }

    setValue(val) {
        this.vueContext.value = val;
    }
}
