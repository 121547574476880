<template>
  <Modal
    :visible="isChangeContactButtonModal"
    @cancel="onCloseChangeModal"
    @ok="onOk"
  >
    <h3>Редактировать кнопку:</h3>
    <div class="buttonChange">
      <div class="field">
        <Input
          :value="buttonName"
          @input="handleInput"
          placeholder="Введите новое название кнопки (Обязательное)*"
          name="inputButtonName"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { Modal, Radio, Input, Button, Icon, Row, Col } from "ant-design-vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ContactButtonModal",
  components: {
    Modal,
    Input,
    RadioGroup: Radio.Group,
    Radio,
    Button,
  },
  data() {
    return {
      buttonName: this.chosenItem?.data.button.parameters.name ?? "",
    };
  },
  computed: {
    ...mapGetters({
      isChangeContactButtonModal: "elements/isChangeButtonModal",
      chosenItem: "elements/chosenItem",
    }),
  },
  methods: {
    ...mapActions({
      setIsChangeButtonModal: "elements/setIsChangeButtonModal",
      updateItem: "elements/updateItem",
    }),

    handleInput: _.debounce(function (e) {
      if (e.target.name === "inputButtonName") {
        this.buttonName = e.target.value;
      }
    }, 500),

    onClose() {
      this.setIsChangeButtonModal(false);
    },

    _getNewElement() {
      return {
        ...this.chosenItem,
        data: {
          ...this.chosenItem.data,
        },
      };
    },

    onOk() {
      if (this.buttonName === "") {
        return;
      }

      //Обновление Data данные для API
      let newEl = this._getNewElement();
      newEl.data.button.parameters.name = this.buttonName

      //Обновление Rete
      let buttonId = [...this.chosenItem.outputs][0][0]
      this.chosenItem.outputs.get(buttonId).name = this.buttonName
      this.chosenItem.update()

      //API запрос
      this.updateItem({ data: newEl });

      this.setIsChangeButtonModal(false);

      // //Сохраняем изменения в node
      // this.chosenItem.data = newEl.data;
    },

    onCloseChangeModal() {
      this.setIsChangeButtonModal(false);
    },
  },
};
</script>

<style lang="sass">
@import "./index.sass"
</style>