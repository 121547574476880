import Rete from "rete";
import VueRenderPlugin from "rete-vue-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import ContextMenuPlugin from "rete-context-menu-plugin";
import MinimapPlugin from "rete-minimap-plugin";

import _ from "lodash";
import * as components from "./components"

// import data from "./data.json" //for Debuging

export default async function (container, items, vueComponent) {
  const editor = new Rete.NodeEditor("demo@0.1.0", container);

  editor.use(VueRenderPlugin);
  editor.use(ConnectionPlugin);
  editor.use(ContextMenuPlugin);
  editor.use(AreaPlugin);
  editor.use(MinimapPlugin);

  const engine = new Rete.Engine("demo@0.1.0");

  Object.values(components).map(c => {
    editor.register(new c());
    engine.register(new c());
  })

  editor.on(
    "nodetranslated",
    _.debounce(function (node) {
      vueComponent.updateItem({ data: node.node })
    }, 1000),
  )

  editor.on('nodecreated', node => {
    let isUserCreatedNode = editor.nodes.length > items.length // pass editor.fromJSON() calls
    if (isUserCreatedNode) {
      if (node.name === 'Image') {
        alert('Для сохранения элемента на сервере обязательно заполните текстовые поля и загрузите изображения')
      }
      if (node.name === 'Condition') {
        alert('Для сохранения элемента на сервере обязательно создайте хотя бы одно логическое условие')
      }
      vueComponent.createItem({ data: node })
    }
  });

  editor.on(
    "connectioncreated connectionremoved",
    _.debounce(function (connection) {
      let updatedNode = connection.output.node

      vueComponent.updateItem({ data: updatedNode })
    }, 1000)
  )

  editor.on(
    "noderemoved",
    function (node) {
      vueComponent.deleteItem({ id: node.id })
    }
  )

  let data = {
    "id": "demo@0.1.0",
    "nodes": items.reduce((acc, value, index) => ({ ...acc, [value.id]: value }), [])
  }

  await editor.fromJSON(data);

  editor.view.resize();
  AreaPlugin.zoomAt(editor);
  setTimeout(() => editor.trigger("process"), 1000);
}

