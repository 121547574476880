import Rete from "rete";

import Component from "./index.vue";

export class SelectControl extends Rete.Control {
    constructor(name, options, selected, emitter ) {
        super(name);

        this.component = Component;
        this.props = {
            name,
            options,
            selected, 
            emitter
        };
    }

    setValue(val) {
        this.vueContext.value = val;
    }
}
