 <template>
  <div>
    <!-- Модальное окно редактирования условий (Condition) -->
    <Modal
      :visible="isChangeConditionButtonsModal"
      @cancel="onClose"
      @ok="onOk"
      title="Редактирование условий"
    >
      <div v-if="this.chosenItem">
        <div
          v-for="(output, index) in this.chosenItem.outputs"
          :key="index"
          class="field"
        >
          <p v-if="output[0] != 'out'">
            <Button
              :block="true"
              @click="openLimitationEditModal(output, index-1)"
              style="overflow: hidden"
            >
            {{ output[1].name }} 
            </Button>
            <Button :block="true" @click="chooseCondition();deleteCondition(output, index-1)">
              <Icon type="delete" />
            </Button>
          </p> 
        </div>
      </div>

      <div class="field">
        <Button
          :block="true"
          @click="
            addNewCondition();
            cleanDataInputs();
          "
          type="primary"
        >
          Добавить новое условие (Condition)
        </Button>
      </div>

      <div class="field">
        <select v-model="limitationOperator" class="ant-input">
          <option value="" disabled>Выберите логическое условие</option>
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
        <select
          @change="handleChange"
          v-model="limitationType"
          class="ant-input"
        >
          <option :value="''" disabled>Выберите тип нового условия</option>
          <option
            v-for="option in options"
            :value="option.value"
            :key="option.text"
          >
            {{ option.text }}
          </option>
        </select>

        <div class="field" v-if="limitationType === 'tag'">
          <Input
            :value="tagName"
            @input="handleInput"
            placeholder="Name"
            name="tagName"
          />
          <Input
            :value="tagValue"
            @input="handleInput"
            placeholder="Value"
            name="tagValue"
          />
        </div>

        <div class="field" v-if="limitationType === 'variable'">
          <Input
            :value="varName"
            @input="handleInput"
            placeholder="Variable name"
            name="varName"
          />
          <select v-model="varOperator" name="varOperator" class="ant-input">
            <option value="=">=</option>
            <option value="!=">!=</option>
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
          <Input
            :value="varValue"
            @input="handleInput"
            placeholder="Value"
            name="varValue"
          />
        </div>

        <div class="field" v-if="limitationType === 'days'">
          <select v-model="selectedDay" class="ant-input">
            <option
              v-for="option in optionsDays"
              :value="option.value"
              :key="option.text"
            >
              {{ option.text }}
            </option>
          </select>
        </div>

        <div class="field" v-if="limitationType === 'time'">
          <Input
            :value="timeValue"
            @input="handleInput"
            placeholder="18:00"
            name="timeValue"
          />
          <select v-model="timeOperator" name="varOperator" class="ant-input">
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
        </div>

        <div class="field" v-if="limitationType === 'datetime'">
          <Input
            :value="datetimeValue"
            @input="handleInput"
            placeholder="23.04.2021:18:00"
            name="datetimeValue"
          />
          <select
            v-model="datetimeOperator"
            name="datetimeOperator"
            class="ant-input"
          >
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
        </div>
      </div>
    </Modal>

    <!-- Модальное окно редактирования limitations в рамках отдельного (Condition) -->
    <Modal
      :visible="isChangeLimitationsModalOpen"
      @cancel="closeLimitationModal"
      @ok="saveLimitation"
      title="Редактирование limitation"
    >
      <div v-if="this.selectedCondition">
        <div
          v-for="(context, index) in this.selectedConditionLimitations"
          :key="index"
          class="field"
        >
          <Button
            :block="true"
            style="overflow: hidden"
          >
            {{ context }}
          </Button>
          <Button :block="true" @click="deleteLimitation(index)">
            <Icon type="delete" />
          </Button>
        </div>
      </div>

      <h3>Параметры limitations:</h3>

      <div class="field">
        <select
          @change="handleChange"
          v-model="limitationType"
          class="ant-input"
        >
          <option :value="''" disabled>Выберите тип нового условия</option>
          <option
            v-for="option in options"
            :value="option.value"
            :key="option.text"
          >
            {{ option.text }}
          </option>
        </select>

        <div class="field" v-if="limitationType === 'tag'">
          <Input
            :value="tagName"
            @input="handleInput"
            placeholder="Name"
            name="tagName"
          />
          <Input
            :value="tagValue"
            @input="handleInput"
            placeholder="Value"
            name="tagValue"
          />
        </div>

        <div class="field" v-if="limitationType === 'variable'">
          <Input
            :value="varName"
            @input="handleInput"
            placeholder="Variable name"
            name="varName"
          />
          <select v-model="varOperator" name="varOperator" class="ant-input">
            <option value="=">=</option>
            <option value="!=">!=</option>
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
          <Input
            :value="varValue"
            @input="handleInput"
            placeholder="Value"
            name="varValue"
          />
        </div>

        <div class="field" v-if="limitationType === 'days'">
          <select v-model="selectedDay" class="ant-input">
            <option
              v-for="option in optionsDays"
              :value="option.value"
              :key="option.text"
            >
              {{ option.text }}
            </option>
          </select>
        </div>

        <div class="field" v-if="limitationType === 'time'">
          <Input
            :value="timeValue"
            @input="handleInput"
            placeholder="18:00"
            name="timeValue"
          />
          <select v-model="timeOperator" name="varOperator" class="ant-input">
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
        </div>

        <div class="field" v-if="limitationType === 'datetime'">
          <Input
            :value="datetimeValue"
            @input="handleInput"
            placeholder="23.04.2021:18:00"
            name="datetimeValue"
          />
          <select
            v-model="datetimeOperator"
            name="datetimeOperator"
            class="ant-input"
          >
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
        </div>
      </div>

      <template #footer>
        <Button key="cancel" @click="closeLimitationModal">Вернуться</Button>
        <Button key="ok" type="primary" @click="saveLimitation"
          >Сохранить</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import * as ReteEngine from "rete";
import * as Socket from "@/node-editor/sockets.js";
import Vue from "vue";

import {
  Modal,
  Radio,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Select,
} from "ant-design-vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import getConditionName from "../../../utils.js"

Vue.use(Modal);

export default {
  name: "ConditionButtonsModal",
  components: {
    Modal,
    Input,
    RadioGroup: Radio.Group,
    Radio,
    Button,
    Icon,
    Row,
    Col,
    Select,
  },
  data() {
    return {
      isAddNewLimitation: true,
      limitationOperator: "",
      tagName: "",
      tagValue: "",
      varName: "",
      varValue: "",
      varOperator: "=",
      timeValue: "",
      timeOperator: ">",
      datetimeValue: "",
      datetimeOperator: ">",
      limitationType: "",
      selectedDay: "",
      options: [
        { text: "Tag", value: "tag" },
        { text: "Variable", value: "variable" },
        { text: "Days", value: "days" },
        { text: "Time", value: "time" },
        { text: "Datetime", value: "datetime" },
      ],
      optionsDays: [
        { text: "", value: "" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
        { text: "Sunday", value: "sunday" },
      ],

      selectedCondition: null,
      selectedConditionIndex: null,
      selectedOutput: null
    };
  },
  computed: {
    ...mapGetters({
      isChangeConditionButtonsModal: "elements/isChangeConditionButtonsModal",
      isChangeLimitationsModalOpen: "elements/isChangeLimitationsModalOpen",
      chosenItem: "elements/chosenItem",
    }),

    buttonName: function () {
      let name = `Type: ${this.limitationType} | Context: `;

      name +=
        this.limitationOperator != "" ? `${this.limitationOperator} ` : "";
      name +=
        this.tagName != "" && this.tagValue != ""
          ? `name: '${this.tagName}' value:'${this.tagValue}' `
          : "";
      name +=
        this.varName != "" && this.varValue != ""
          ? `${this.varName}${this.varOperator}${this.varValue} `
          : "";
      name +=
        this.timeValue != "" ? `${this.timeOperator}${this.timeValue}` : "";
      name +=
        this.datetimeValue != ""
          ? `${this.datetimeOperator}${this.datetimeValue}`
          : "";
      name += this.selectedDay != "" ? `${this.selectedDay} ` : "";
      name.replace(/\s{2,}/g, " ");

      return name;
    },

    selectedConditionLimitations: function () {
      if (this.selectedCondition) {
        return this.selectedCondition.limitations;
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      setIsChangeConditionButtonsModal:
        "elements/setIsChangeConditionButtonsModal",
      setIsChangeLimitationsModalOpen:
        "elements/setIsChangeLimitationsModalOpen",
      updateItem: "elements/updateItem",
    }),

    handleInput: _.debounce(function (e) {
      let name = e.target.name;
      switch (name) {
        case "tagName":
          this.tagName = e.target.value;
          break;
        case "tagValue":
          this.tagValue = e.target.value;
          break;
        case "varName":
          this.varName = e.target.value;
          break;
        case "varOperator":
          this.varOperator = e.target.value;
          break;
        case "varValue":
          this.varValue = e.target.value;
          break;
        case "timeValue":
          this.timeValue = e.target.value;
          break;
        case "timeOperator":
          this.timeOperator = e.target.value;
          break;
        case "datetimeValue":
          this.datetimeValue = e.target.value;
          break;
        case "datetimeOperator":
          this.datetimeOperator = e.target.value;
          break;
      }
    }, 100),

    handleChange() {
    },

    openLimitationEditModal(output, index, event) {
      this.setIsChangeLimitationsModalOpen(true);
      this.selectedCondition = output[1]?.node.data[index];
      this.selectedConditionIndex = index;
      this.selectedOutput = output
    },

    _getNewElement() {
      return {
        ...this.chosenItem,
        data: [
          ...this.chosenItem.data,
        ],
      };
    },

    addNewCondition() {
      // Временно преобразуем Obj в Array для работы с индексами

      //  Добавление Output в Rete
      if (this.limitationOperator) {
        // Добавляем Output изменения в Rete
        let condName = "Оператор: " + String(this.limitationOperator).toUpperCase() + ", условий: " + String(1);
        let buttonUnicalId = String(Date.now());
        let buttonOutput = new ReteEngine.Output(
          buttonUnicalId,
          condName,
          Socket.value,
          false
        );
        this.chosenItem.addOutput(buttonOutput);

        //Добавляем новый элемент в данные
        let isArray = Object.prototype.toString.call( this.chosenItem.data ) === '[object Array]'
        if ( isArray ){
          this.chosenItem.data.push({
            target: null,
            operation: this.limitationOperator,
            limitations: [],
          });
        }
        else {
            this.chosenItem.data = [
              {
                target: null,
                operation: this.limitationOperator,
                limitations: [],
              }
            ]
        }


        let obj = this.chosenItem.data

        this.chosenItem.data = Object.keys(obj).map((key) => obj[key]);;
        this.selectedConditionIndex = this.chosenItem.data.length-1;
        this.chosenItem.update();

        //Добавляем первый limitation
        this.saveLimitation();
      } else
        alert(
          "Для создания новой группы условий выберите логический оператор и укажите первое условие"
        );
    },


    saveLimitation() {
      let newEl = this._getNewElement();
      let is_exit = false

      //Создаем новый Limitation
      if (this.isAddNewLimitation) {
        let newLimitation = {};
    
        //Заполняем данные
        newLimitation.type = this.limitationType;
        switch (this.limitationType) {
          case "tag":
            if (this.tagName === "" || this.tagValue == "") {
              alert("Заполните все поля условия");
              is_exit = true
            }

            newLimitation.context = {
              name: this.tagName,
              value: this.tagValue,
            };
            break;

          case "variable":
            if (
              this.varName === "" ||
              this.varOperator == "" ||
              this.varValue == ""
            ) {
              alert("Заполните все поля условия");
              is_exit = true
            }

            newLimitation.context = {
              variable: this.varName,
              operator: this.varOperator,
              value: this.varValue,
            };
            break;

          case "days":
            if (this.selectedDay === "") {
              alert("Заполните все поля условия");
              is_exit = true
            }

            newLimitation.context = {
              value: this.selectedDay,
            };
            break;

          case "time":
            if (this.timeValue === "" || this.timeOperator === "") {
              alert("Заполните все поля условия");
              is_exit = true
            }

            newLimitation.context = {
              value: this.timeValue,
              operator: this.timeOperator,
            };
            break;

          case "datetime":
            if (this.datetimeValue === "" || this.datetimeOperator === "") {
              alert("Заполните все поля условия");
              is_exit = true
            }

            newLimitation.context = {
              value: this.datetimeValue,
              operator: this.datetimeOperator,
            };
            break;
          default:
              alert("Выберите тип нового условия и заполните поля");
              is_exit = true
        }

        if (is_exit){
          return;
        }

        newEl.data[this.selectedConditionIndex].limitations.push(newLimitation);

        //Сохраняем в API
        this.updateItem({ data: newEl });

        //Сохраняем изменения в node
        this.chosenItem.data = newEl.data;
      }

        // Актуализируем название Output
        if(this.selectedOutput){
          let old = this.chosenItem.outputs.get(this.selectedOutput[0])
          let updatedOutput = old
          updatedOutput.name = getConditionName(this.selectedCondition.operation, newEl.data[this.selectedConditionIndex].limitations.length)

          this.chosenItem.outputs.set(old.key, updatedOutput)
          this.chosenItem.update()
        }
       
        
        //Получить нужный output и изменить его название

      
      //Очищаем поля ввода
      this.cleanDataInputs()
    },

    closeLimitationModal() {
      this.setIsChangeLimitationsModalOpen(false);
      this.cleanDataInputs();
    },

    cleanDataInputs() {
      this.limitationType = ''
      this.limitationOperator = ''
      this.isAddNewLimitation = true, 
      this.tagName = "";
      this.tagValue = "";

      this.varName = "";
      this.varValue = "";
      this.varOperator = "=";

      this.timeValue = "";
      this.timeOperator = ">";

      this.datetimeValue = "";
      this.datetimeOperator = ">";

      this.selectedDay = "";
      this.selectedOutput = null;
    },

    deleteLimitation(limitationIndex, event) {
      //TODO check it for correctness
      let newEl = this._getNewElement();
      console.log(
        newEl.data[this.selectedConditionIndex],
        this.selectedConditionIndex,
        limitationIndex
      );

      newEl.data[this.selectedConditionIndex].limitations.splice(
        limitationIndex,
        1
      );

      this.chosenItem.data = newEl.data;
      this.updateItem({ data: newEl });
      this.cleanDataInputs();
    },

    deleteCondition(output, index, event){
      //Удаление Output в Rete
      this.chosenItem.outputs.delete(output[0])
      this.chosenItem.update();

      //Удаление кнопки из Data в API
      console.log(index)
      let newEl = this._getNewElement()
      newEl.data.splice(index, 1);

      //Запрос
      this.chosenItem.data = newEl.data;
      this.updateItem({ data: newEl });
      this.cleanDataInputs();
      this.$forceUpdate()
    },

    onOk() {
      this.setIsChangeLimitationsModalOpen(false)
      this.setIsChangeConditionButtonsModal(false)
    },

    onClose() {
      this.setIsChangeConditionButtonsModal(false);
      this.cleanDataInputs();
    },

    chooseCondition() {
    }
  },
};
</script>

<style lang="sass">
@import "./index.sass"
</style>