<template>
  <Select
    :name="name"
    :options="options"
    :change="onChangeValue"
    :selected="newValue"
  />
</template>

<script>
import Select from "@/components/Forms/Select";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: { Select },
  props: ["name", "options", "selected", "emitter"],
  data() {
    return {
      newValue: this.selected,
    };
  },
  methods: {
    ...mapActions({
      updateItem: "elements/updateItem",
    }),

    onChangeValue: _.debounce(function (e) {
      this.newValue = e.target.value
      this.updateItem({ data: this.emitter.selected.list[0] });
    }, 500),
  },
};
</script>
