import Rete from "rete";

import Component from "./index.vue";

export class InputControl extends Rete.Control {
    constructor(type, name, emitter, value, placeholder, readonly) {
        super(name);

        this.component = Component;
        this.props = {
            type,
            name,
            emitter,
            value,
            placeholder,
            readonly
        };
    }

    setValue(val) {
        this.vueContext.value = val;
    }
}
