import Rete from "rete";

import * as Socket from "@/node-editor/sockets";
import { InputControl } from "@/node-editor/controls/index.js";

export class Tag extends Rete.Component {
  constructor() {
    super("Tag");
  }

  builder(node) {
    //стандартные ввод и вывод для соединений для всех элементов
    const data = node.data;
    const input = new Rete.Input("in", "", Socket.value, true);
    const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

    const name = new InputControl("input", "name", this.editor, data?.name ?? "_");
    const value = new InputControl("input", "value", this.editor, data?.value ?? "_");


    return node
      .addInput(input)
      .addOutput(out)
      .addControl(name)
      .addControl(value)
      ;
  }


  worker(node, inputs, outputs) {

  }
}
