import Vue from 'vue'
import Vuex from 'vuex'

import elements from "./modules/elements"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    elements
  }
})
window.store = store
export default store