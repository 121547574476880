import Rete from "rete";

import * as Socket from "@/node-editor/sockets";
import { UploadControl, ButtonControl, InputControl } from "@/node-editor/controls/index.js";
import { storageURL } from "../../api";

export class Image extends Rete.Component {
  constructor() {
    super("Image");
  }

  builder(node) {
    let data = node.data

    //стандартные ввод и вывод для соединений для всех элементов
    const input = new Rete.Input("in", "", Socket.value, true);
    const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

    // TODO image Controls
    const textAll = new InputControl("textArea", "textAll", this.editor, data.text?.all);
    const imgAll = new UploadControl("all", data.image?.all ?? "", this.editor);

    const textRed = new InputControl("textArea", "textRed", this.editor, data.text?.red);
    const imgRed = new UploadControl("red", data.image?.red ?? "", this.editor);

    const textBlue = new InputControl("textArea", "textBlue", this.editor, data.text?.blue);
    const imgBlue = new UploadControl("blue", data.image?.blue ?? "", this.editor);

    const textGreen = new InputControl("textArea", "textGreen", this.editor, data.text?.green);
    const imgGreen = new UploadControl("green", data.image?.green ?? "", this.editor);

    const textYellow = new InputControl("textArea", "textYellow", this.editor, data.text?.yellow);
    const imgYellow = new UploadControl("yellow", data.image?.yellow ?? "", this.editor);

    const button = new ButtonControl("Управлять кнопками", "setIsAddButtonModal", node.id, this.editor);

    //Отрисовка кнопок
    if (data.buttons) {
      let i = 0
      data.buttons.map(element => {
        let name = String(element.type) + String(element.target) + i // TODO: name generator depencies in mutation.js, should move to common instruments functions uses in mutation.js
        let buttonOutput = new Rete.Output(name, element.parameters.name, Socket.value, false);
        node.addOutput(buttonOutput);
        i++;
      });
    }

    return node
      .addInput(input)
      .addOutput(out)
      .addControl(textAll)
      .addControl(imgAll)
      .addControl(textRed)
      .addControl(imgRed)
      .addControl(textBlue)
      .addControl(imgBlue)
      .addControl(textGreen)
      .addControl(imgGreen)
      .addControl(textYellow)
      .addControl(imgYellow)
      .addControl(button);

  }

  worker(node, inputs, outputs) {

  }
}
