<template>
  <Button :fullWidth="true" :click="click">
    {{ content }}
  </Button>
</template>

<script>
import { mapActions } from "vuex";
import Button from "@/components/Common/Button";
import Rete from "rete";
import * as Socket from "@/node-editor/sockets.js";

export default {
  components: {
    Button,
  },
  props: ["content", "funcName", "emitter"],
  methods: {
    ...mapActions({
      setIsAddButtonModal: "elements/setIsAddButtonModal",
      setChosenItem: "elements/setChosenItem",
      setIsChangeButtonModal: "elements/setIsChangeButtonModal",
      setIsChangeContactButtonModal: "elements/setIsChangeContactButtonModal",
      setIsChangeConditionButtonsModal: "elements/setIsChangeConditionButtonsModal",
    }),
    click() {
      let node = this.emitter.selected.list[0];
      this.setChosenItem(node); //Сохраняем Node от Rete с которым происходит работа

      switch (this.funcName) {
        case "setIsChangeButtonModal":
          this.setIsChangeButtonModal(true);
          break;
        case "setIsChangeContactButtonModal":
          this.setIsChangeContactButtonModal(true);
          break;
        case "setIsChangeConditionButtonsModal":
          this.setIsChangeConditionButtonsModal(true);
          break;
        case "setIsAddButtonModal":
          this.setIsAddButtonModal(true);
          break;
      }
    },
  },
};
</script>
