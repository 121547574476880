import Rete from "rete";

import Component from "./index.vue";

export class UploadControl extends Rete.Control {
    constructor(name, value, emitter) {
        super(name);

        this.component = Component;
        this.props = {
            name,
            value,
            emitter
        };
    }

    setValue(val) {
        this.vueContext.value = val;
    }
}
