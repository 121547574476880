import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const state = () => ({
    items: [],
    chosenItem: null,
    isAddButtonModal: false,
    isChangeButtonModal: false, //модальное окно изменения кнопок (Message, Image)
    isChangeContactButtonModal: false, //модальное окно изменения кнопки Contact
    isChangeConditionButtonsModal: false, //модальное окно изменения кнопок Condtition
    isChangeLimitationsModalOpen: false, //модальное окно изменения Limitations в рамках одного Condition
})

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}