import Rete from "rete";

import * as Socket from "@/node-editor/sockets";
import { ButtonControl } from "@/node-editor/controls/index.js";
import getConditionName from "../../utils.js"

export class Condition extends Rete.Component {
  constructor() {
    super("Condition");
  }

  builder(node) {
    // const data = JSON.stringify(node.data[0].limitations);
    const data = node.data;

    //стандартные ввод и вывод для соединений для всех элементов
    const input = new Rete.Input("in", "", Socket.value, true);
    const out = new Rete.Output("out", "Если ни одно решение не подошло:", Socket.value, false);
    const button = new ButtonControl("Управлять кнопками", "setIsChangeConditionButtonsModal", node.id, this.editor);

    // const limitation = new InputControl("textArea", "textAll", this.editor, data);

    node
      .addOutput(out)
      .addInput(input)
      .addControl(button);

    //Отрисовка условий
    if (node.data.length) {
      let count = 0

      data.map(condition => {
        let name = String(condition.operation) + String(condition.target) + String(node.id) + count // TODO move to common functions, also uses in Condition component name 
        let value = getConditionName(condition.operation, condition.limitations.length)
        let limitationOutput = new Rete.Output(name, value, Socket.value, false);
        node.addOutput(limitationOutput);
        count++
      });
    }

    return node
  }

  worker(node, inputs, outputs) {

  }
}
