<template>
  <div id="rete" ref="rete" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import init from "@/node-editor";

export default {
  data() {
    return {
      'isFirstInit': true,
    };
  },
  watch: {
    elements: function () {
      this.showItems();
    },
  },
  computed: {
    ...mapGetters({
      elements: "elements/items",
    }),
  },
  mounted() {
    this.setElements();
  },
  methods: {
    ...mapActions({
      setElements: "elements/setItems",
      formatItems: "elements/formatItems",
      updateItem: "elements/updateItem",
      deleteItem: "elements/deleteItem",
      createItem: "elements/createItem",
      updateNodeIdByServerResponse: "elements/updateNodeIdByServerResponse",
    }),
    showItems() {
      if (this.elements && this.isFirstInit) {
        this.isFirstInit = false; //убрать повторную инициализацию на каждое добавление элемента
        init(this.$refs.rete, this.elements, this);
      }
    },
  },
};
</script>

<style>
#rete {
  width: 100% !important;
  height: 100vh !important;
}
</style>
