<template>
  <Upload :name="name" :value="newValue" :change="change" />
</template>

<script>
import Upload from "@/components/Forms/Upload";
import { mapActions } from "vuex";


export default {
  components: { Upload },
  props: ["name", "value", "emitter"],
  data() {
    return {
      newValue: this.value,
    };
  },
  methods: {
    ...mapActions({
      updateItem: "elements/updateItem",
    }),

    change(info) {
      let fileList = [...info.fileList];

      fileList = fileList.map((file) => {
        if (file.response) {
          let chosenItem = this.emitter.selected.list[0];
          let res = file.response.response;
          file.url = res.link;
          file.name = res.filename;

          if (!chosenItem.data.hasOwnProperty("image")) {
            chosenItem.data.image = new Object();
            chosenItem.data.image[this.name] = file.name;
          }

          //Update Rete JS data for object
          chosenItem.data.image[this.name] = file.name;
          chosenItem.update();
          this.newValue = file.name

          //Send API update request
          this.updateItem( { data: chosenItem} )
        }
        return file;
      });
    },
  },
};
</script>
