import Rete from "rete";

import * as Socket from "@/node-editor/sockets";
import { InputControl, ButtonControl } from "@/node-editor/controls/index.js";

export class Contact extends Rete.Component {
  constructor() {
    super("Contact");
  }

  builder(node) {
    const data = node.data

    //стандартные ввод и вывод для соединений для всех элементов
    const input = new Rete.Input("in", "", Socket.value, true);
    const out = new Rete.Output("out", "Следующее звено:", Socket.value, false);

    const textAll = new InputControl("textArea", "textAll", this.editor, data?.text?.all ?? "");
    const textRed = new InputControl("textArea", "textRed", this.editor, data?.text?.red ?? "");
    const textBlue = new InputControl("textArea", "textBlue", this.editor, data?.text?.blue ?? "");
    const textGreen = new InputControl("textArea", "textGreen", this.editor, data?.text?.green ?? "");
    const textYellow = new InputControl("textArea", "textYellow", this.editor, data?.text?.yellow ?? "");
    const buttonEditor = new ButtonControl("Управлять кнопками", "setIsChangeContactButtonModal", node.id, this.editor);

    // добавляем кнопку (у контакта всегда одна)
    let button = data.button
    if (button) {
      let name = String(button.type) + String(button.target) // TODO: bullshit code, should move to common instruments functions uses in mutation.js
      let buttonOutput = new Rete.Output(name, button.parameters.name, Socket.value, false);
      node.addOutput(buttonOutput);
    }


    return node
      .addInput(input)
      .addOutput(out)
      .addControl(textAll)
      .addControl(textRed)
      .addControl(textBlue)
      .addControl(textGreen)
      .addControl(textYellow)
      .addControl(buttonEditor)

  }

  worker(node, inputs, outputs) {
    // inputs['button'] = ""
  }
}