import axios from "axios";

import configs from "@/configs"
const funnelId = get_funnel_id()

export const baseURL = `${configs.baseURL}/api/v1/funnels/${funnelId}`
export const TOKEN = `${configs.token}`
export const storageURL = `${configs.baseURL}/storage/funnel/`

export const instance = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${TOKEN}`
  },
});

function get_funnel_id(){
  var url_string = window.location.href
  var url = new URL(url_string);
  var c = url.searchParams.get("funnel");
  return c
}

export { elementAPI } from "./elements"