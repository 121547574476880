import { elementAPI } from "@/api"

export default {
    async setItems({ commit }) {
        const items = await elementAPI.fetchItems()
        if (items) {
            commit('formatItems', items);
            commit('setItems', items);
        }
    },

    async deleteItem({ commit }, { id }) {
        await elementAPI.deleteItem(id)
    },

    /**
     * 
     * @param {data} Node from ReteJS
     */
    async updateItem({ dispatch }, { data }) {
        const formattedData = _formatData(data)
        const response = await elementAPI.update(formattedData)
        if (response === undefined){
            await dispatch('createItem', {data: data} )
        }
    },

    /**
    *
    * @param {data} Node from ReteJS
    */
    async createItem({ commit, dispatch }, {data} ) {

        let formattedData = _formatData(data)
        commit('create', formattedData)

        const response = await elementAPI.create(formattedData)        
        dispatch('updateNodeIdByServerResponse', { oldNode: formattedData, nodeByServerResponse: response, reteNode: data})
    },

    setChosenItem({ commit }, chosenItem) {
        commit('setChosenItem', chosenItem)
    },
    setIsAddButtonModal({ commit }, isAddButtonModal) {
        commit('setIsAddButtonModal', isAddButtonModal)
    },
    setIsChangeButtonModal({ commit }, isChangeButtonModal) {
        commit('setIsChangeButtonModal', isChangeButtonModal)
    },

    setIsChangeContactButtonModal({ commit }, isChangeContactButtonModal) {
        commit('setIsChangeButtonModal', isChangeContactButtonModal)
    },

    setIsChangeConditionButtonsModal({ commit }, isChangeConditionButtonsModal) {
        commit('setIsChangeConditionButtonsModal', isChangeConditionButtonsModal)
    },

    setIsChangeLimitationsModalOpen({ commit }, isChangeLimitationsModalOpen) {
        commit('setIsChangeLimitationsModalOpen', isChangeLimitationsModalOpen)
    },

    updateNodeIdByServerResponse({ commit }, { oldNode, nodeByServerResponse, reteNode}){
        commit('updateNodeIdByServerResponse', { oldNode: oldNode, nodeByServerResponse: nodeByServerResponse, reteNode: reteNode})
    }
}

/**
 * Приведение данных из ReteJS к формату API
 * 
 * @param {data} Node from ReteJS
 * @returns 
 */
function _formatData(data) {
    //определяем тип элемента
    let formattedData = null

    switch (data.name) {
        case 'Message':
            formattedData = formatMessage(data);
            break;

        case 'Contact':
            formattedData = formatContact(data);
            break;

        case 'Launcher':
            formattedData = formatLauncher(data);
            break;

        case 'Tag':
            formattedData = formatTag(data);
            break;

        case 'Condition':
            formattedData = formatCondition(data);
            break;

        case 'Timer':
            formattedData = formatTimer(data);
            break;

        case 'Image':
            formattedData = formatImage(data);
            break;
    }
    return formattedData;
}

function formatLauncher(componentData) {
    let id = componentData.id
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null
    let position = componentData.position //[x,y]

    let formattedData =
    {
        "id": id,
        "type": "launcher",
        "target": target,
        "parameters": [],
        "position": position,
    }

    return formattedData
}

function formatMessage(componentData) {
    let id = componentData.id

    //structure src/node-editor/controls/Input/index.vue 
    let textValuePath = componentData.controls
    let textAll = textValuePath.get('textAll').vueContext.newValue
    let textRed = textValuePath.get('textRed').vueContext.newValue
    let textBlue = textValuePath.get('textBlue').vueContext.newValue
    let textGreen = textValuePath.get('textGreen').vueContext.newValue
    let textYellow = textValuePath.get('textYellow').vueContext.newValue


    // setted by JSON structure from customer API docs
    let position = { "block": componentData.position[0], "index": componentData.position[1] }
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null


    //WARNING! Система не предназначена для множественных соединений, из-за connections[0], 
    // соблюдение условия гарантируется в Message.js output multiply connections false для output
    let buttons = componentData.data?.buttons ?? []

    let i = 0
    componentData.outputs.forEach(
        (output, name) => {
            if (name != "out") { //см node-editor/components/Message.js "out" - значение по-умолчанию, не кнопка
                let buttonTarget = output.connections[0]?.input.node.id ?? null
                if (buttons[i]) { //обновление компонентов
                    buttons[i].target = buttonTarget
                }
                i++;
            }
        }
    )

    // "type":["The type field is required."],"parameters":["The parameters field is required."]}}

    let formattedData =
    {
        "id": id,
        "type": "message",
        "target": target,
        "parameters": {
            "text": {
                "all": textAll,
                "red": textRed,
                "blue": textBlue,
                "green": textGreen,
                "yellow": textYellow
            },
            "buttons": buttons,
        },
        "position": position,
    }

    return formattedData
}

function formatContact(componentData) {
    let id = componentData.id
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null
    let position = componentData.position //[x,y]

    let textValuePath = componentData.controls
    let textAll = textValuePath.get('textAll').vueContext.newValue
    let textRed = textValuePath.get('textRed').vueContext.newValue
    let textBlue = textValuePath.get('textBlue').vueContext.newValue
    let textGreen = textValuePath.get('textGreen').vueContext.newValue
    let textYellow = textValuePath.get('textYellow').vueContext.newValue

    //Требование для POST на создание нового элемента
    if (textRed === '' || textBlue === '' || textGreen === '' || textYellow === '') {
        textAll = "Текст сообщения"
    }


    let button = componentData.data?.button ?? null //старые данные, записанные при создании с сервера
    if (button) {
        let i = 0
        componentData.outputs.forEach(
            (output, name) => {
                if (name != "out") { //см node-editor/components/Message.js "out" - значение по-умолчанию, не кнопка
                    button.target = output.connections[0]?.input.node.id ?? null
                }
                i++;
            }
        )
    }
    else {
        //POST на создание компонентов, кнопка не может быть null
        button = {
            "type": "contact",
            "target": null,
            "parameters": {
                "name": "_"
            }
        }
    }

    let formattedData =
    {
        "id": id,
        "type": "contact",
        "target": target,
        "parameters": {
            "text": {
                "all": textAll,
                "red": textRed,
                "blue": textBlue,
                "green": textGreen,
                "yellow": textYellow
            },
            "button": button,
        },
        "position": position,
    }

    return formattedData

}

function formatImage(componentData) {
    // formattedData = componentData

    let id = componentData.id

    //structure src/node-editor/controls/Input/index.vue 
    let textValuePath = componentData.controls
    let textAll = textValuePath.get('textAll').vueContext.newValue
    let textRed = textValuePath.get('textRed').vueContext.newValue
    let textBlue = textValuePath.get('textBlue').vueContext.newValue
    let textGreen = textValuePath.get('textGreen').vueContext.newValue
    let textYellow = textValuePath.get('textYellow').vueContext.newValue

    let imgAll = textValuePath.get('all').vueContext.newValue
    let imgRed = textValuePath.get('red').vueContext.newValue
    let imgBlue = textValuePath.get('blue').vueContext.newValue
    let imgGreen = textValuePath.get('green').vueContext.newValue
    let imgYellow = textValuePath.get('yellow').vueContext.newValue

    let images = {}
    if (imgAll != '') images['all'] = imgAll
    if (imgRed != '') images['red'] = imgRed
    if (imgBlue != '') images['blue'] = imgBlue
    if (imgGreen != '') images['green'] = imgGreen
    if (imgYellow != '') images['yellow'] = imgYellow

    // setted by JSON structure from customer API docs
    let position = { "block": componentData.position[0], "index": componentData.position[1] }
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null

    //WARNING! Система не предназначена для множественных соединений, из-за connections[0], 
    // соблюдение условия гарантируется в Message.js output multiply connections false для output
    let buttons = componentData.data?.buttons ?? []

    let i = 0
    componentData.outputs.forEach(
        (output, name) => {
            if (name != "out") { //см node-editor/components/Message.js "out" - значение по-умолчанию, не кнопка
                let buttonTarget = output.connections[0]?.input.node.id ?? null
                if (buttons[i]) { //обновление компонентов
                    buttons[i].target = buttonTarget
                }
                i++;
            }
        }
    )

    // "type":["The type field is required."],"parameters":["The parameters field is required."]}}

    let formattedData =
    {
        "id": id,
        "type": "image",
        "target": target,
        "parameters": {
            "text": {
                "all": textAll,
                "red": textRed,
                "blue": textBlue,
                "green": textGreen,
                "yellow": textYellow
            },
            "image": images,
            "buttons": buttons,
        },
        "position": position,
    }

    return formattedData
}

function formatTimer(componentData) {
    let id = componentData.id
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null
    let position = componentData.position //[x,y]

    // Предыдущие назначения до обновления
    let days = []

    //изменения от пользователя
    let textValuePath = componentData.controls

    //заполняем актуальный список days
    componentData.controls.forEach((key, value) => {
        if (key.vueContext.newChecked) {
            days.push(value)
        }
    })

    let delayData = {
        "type": textValuePath.get('timeType').vueContext.newValue,
        "value": textValuePath.get('time').vueContext.newValue
    }

    let range = {
        "endAt": textValuePath.get('durationTo').vueContext.newValue,
        "startAt": textValuePath.get('durationFrom').vueContext.newValue
    }
    if (range['endAt'] === '') range['endAt'] = "23:59" //По-умолчанию если не заполнено
    if (range['startAt'] === '') range['startAt'] = "00:00" // По - умолчанию если не заполнено


    let formattedData = {
        "id": id,
        "type": "timer",
        "target": target,
        "parameters": {
            "days": days,
            "delay": delayData,
            "range": range
        },
        "position": position,
    }

    return formattedData
}

function formatCondition(componentData) {
    let id = componentData.id
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null
    let position = componentData.position

    //Удаляем лишние свойства   

    let parameters = Object.values(componentData.data)

    //Обновление connection для каждого limitation

    let i = 0
    componentData.outputs.forEach(
        (output, name) => {
            if (name != "out") { //см node-editor/components/Message.js "out" - значение по-умолчанию, не кнопка
                let buttonTarget = output.connections[0]?.input.node.id ?? null
                if (parameters[i]) { //обновление компонентов
                    parameters[i].target = buttonTarget
                }
                i++;
            }
        }
    )

    console.log("pams --> ", parameters)

    let formattedData = {
        "id": id,
        "type": "condition",
        "target": target,
        "parameters": parameters,
        "position": position,
    }

    return formattedData
}

function formatTag(componentData) {

    let id = componentData.id
    let target = componentData.outputs.get('out').connections[0]?.input.node.id ?? null
    let position = componentData.position

    let tagName = componentData.controls.get('name').vueContext.newValue ?? "_"
    let tagValue = componentData.controls.get('value').vueContext.newValue ?? "_"

    let formattedData =
    {
        "id": id,
        "type": "tag",
        "target": target,
        "parameters": {
            "name": tagName,
            "value": tagValue,
        },
        "position": position,
    }

    return formattedData
}